<div class="termsWrapper">
  <h4>Terms Of Use</h4>
  <div class="termscontent-wrap">
    <p>
      The terms “We” / “Us” / “Our”/ “Gyan Fresh” individually and collectively
      refer to C.P. Milk and Food Products Private Limited
      (<strong>“Company”</strong>) and the terms “User”/ “You” / “Your”
      individually and collectively refer to the “Users” of the application.
    </p>
    <p>
      These terms of use (<strong>“Terms of Use”</strong>) mandate the terms on
      which Users interested in browsing or availing the services provided by
      this mobile application owned and operated by C.P. Milk and Food Products
      Private Limited (<strong>“Platform”</strong>). Please read the Terms of
      Use carefully before using the Platform or registering on the Platform or
      accessing any material information through the Platform.
    </p>
    <p>
      The Company reserves its right to revise these Terms of Use at any time by
      updating the same on the Platform. You should visit this page periodically
      to re-appraise Yourself of the updated Terms of Use, because they are
      binding on all the visitors and users of the Platform.
    </p>
    <p>
      By accessing or using the services provided by Gyan Fresh through the Gyan
      Fresh Platform, You agree to be bound by the terms described herein and
      all terms incorporated by reference. If You do not agree to all of these
      terms, do not use the services provided by the Platform.
    </p>
    <div class="contentswrap">
      <ol>
        <li>
          <h5>Eligibility</h5>
          <ol>
            <li>
              Services of the Platform would be available to only selected
              geographies in India. Persons who are "incompetent to contract"
              within the meaning of the Indian Contract Act, 1872 including
              un-discharged insolvents etc. are not eligible to use the
              Platform. If You are a minor i.e. under the age of 18 years but at
              least 13 years of age you may use the Platform only under the
              supervision of a parent or legal guardian who agrees to be bound
              by these Terms of Use. If Your age is below 18 years Your parents
              or legal guardians can transact on behalf of You if they are
              Registered Users.
            </li>
          </ol>
        </li>
        <li>
          <h5>Account and Registration</h5>
          <ol>
            <li>
              While all Users can browse through the Platform and the services
              provided therein by simply downloading the Platform, Users
              desirous of placing an order through the Platform will have to
              register and login by creating an account on the Platform (<strong
                >“Registered User”</strong
              >).
            </li>
            <li>
              Registered Users will have to keep their account and registration
              details current and correct for communications related to their
              purchases from the Platform. By agreeing to the Terms of Use, You
              agree to receive promotional communication and newsletters upon
              registration. A Registered User can opt out by contacting customer
              service.
            </li>

            <li>
              Users shall be solely responsible for maintaining the
              confidentiality of the Account information, and for all the
              activities that occur under their account. You agree to
              immediately notify Gyan Fresh of any unauthorized use of Your
              Account information or any other breach of security. We will not
              be liable for any loss or damage arising from your failure to
              comply with this provision. You may be held liable for losses
              incurred by Gyan Fresh or any other User of or visitor to the
              Platform due to authorized or unauthorized use of Your account as
              a result of your failure in keeping Your account information
              secure and confidential. Use of another User’s account information
              for using the Platform is strictly prohibited.
            </li>
          </ol>
        </li>
        <li>
          <h5>Pricing</h5>
          <ol>
            <li>
              All the products listed on the Platform will be sold in Indian
              Rupee (INR) at Maximum Retail Price (MRP) unless otherwise
              specified. The prices mentioned at the time of ordering will be
              the prices charged on the date of the delivery inclusive of all
              taxes. Although prices of most of the products do not fluctuate on
              a daily basis, any such change in the price of any product shall
              be duly intimated to the Users at the time of placing their
              orders.
            </li>
          </ol>
        </li>
        <li>
          <h5>Delivery Policy</h5>
          <ol>
            <li>
              Gyan Fresh provides the service of doorstep delivery of fresh milk
              and milk products on the time slot selected by the User. The real
              time delivery status is updated and informed to You through the
              notification messages or email instantly.
            </li>
            <li>
              However, the delivery orders are accepted only if the User’s
              delivery location falls within the permitted delivery zone of the
              designated Gyan Fresh outlet and if the concerned outlet is
              available to accept and process the orders.
            </li>
            <li>
              In the event that a non-delivery occurs on account of Your mistake
              (i.e. wrong name or address or any other wrong information or
              non-availability at the designated time slot) any extra cost
              incurred by Us for redelivery shall be claimed from You.
            </li>
            <li>In Gyan Fresh App Minimum Cart Value and Delivery Charges are applicable based on Order Delivering City. This amount will be deducted respectively from customers' Wallet.
            </li>
          </ol>
        </li>
        <li>
          <h5>Payment Policy</h5>
          <ol>
            <li>
              Payment for in-app purchases for the product takes place through
              prepaid Gyan Fresh User e-wallet facility provided on the Platform
              subject to availability of sufficient balance in the User’s
              e-wallet equivalent to or more than the total purchase price of
              the order at the time of purchasing. In the event that the User’s
              Gyan Fresh e-wallet does not have the sufficient amount equivalent
              to the total price of the order intended to be placed, the order
              will not be accepted by Us to be delivered to You.
            </li>
            <li>
              The Gyan Fresh e-wallet is the property of Gyan Fresh to whom it
              must be surrendered, upon request. The following terms and
              conditions shall apply to the prepaid account of your Gyan Fresh
              e-wallet. By using the Gyan Fresh e-wallet, You agree to the below
              mentioned Terms and Conditions. Please ensure that You have
              carefully read and understood these Terms and Conditions before
              using Your Gyan Fresh e-wallet. By using the Gyan Fresh e-wallet
              You confirm that you have read, understood and accepted the
              detailed Terms and Conditions of the Gyan Fresh e-wallet.
              <ol class="chngeicon">
                <li>
                  <h6>Eligibility</h6>
                  To be eligible for the Gyan Fresh e-wallet membership, You
                  should be competent to enter into a contract i.e. You should
                  have attained the age of majority according to Indian laws.
                  Gyan Fresh e-wallet is not targeted towards, nor intended for
                  use by anyone who has not attained the age of majority. Any
                  person under the age of 18, may only use the Gyan Fresh
                  e-wallet under the supervision of a parent or legal guardian
                  who agrees to be bound by these Terms and Conditions.
                </li>
                <li>
                  <h6>Activation</h6>
                  Gyan Fresh e-wallet is automatically created and enabled as
                  soon as You register for Gyan Fresh Platform’s services while
                  accepting these Terms and Conditions. The e-wallet gets
                  activated by loading money in it either through credit card /
                  debit card / UPI / Internet Banking / any other RBI approved
                  e-wallets managed and governed by other private or government
                  entities by visiting the Gyan Fresh Platform. However, there
                  has to be sufficient balance equivalent to the total amount of
                  order intended to be placed through the Platform, at the time
                  of placing the order, for it to be accepted and processed. The
                  balance on the Gyan Fresh e-wallet have validity of six months.
                  <br />
                  We may change the maximum and minimum amounts at any time by
                  notifying you at the point of recharge, and such change shall
                  not constitute an amendment to these Terms and Conditions. No
                  credit card, credit line, overdraft protection or deposit
                  account is associated with a Gyan Fresh e-wallet. The balance
                  loaded onto your Gyan Fresh e-wallet is non-refundable and may
                  not be exchanged for cash or credit balance. No interest,
                  dividends or any other earnings on funds deposited to a Gyan
                  Fresh e-wallet will accrue or be paid or credited to You by
                  Us.
                  <br />
                  All amounts loaded onto your Gyan Fresh e-wallet are held and
                  denominated in Indian Rupees.
                  <br/>
                  Any cashback credited by the company is valid only up to 90 days. Customers are requested to use cashback within 90 days otherwise the company holds the right to forfeit the amount.

                </li>
                <li>
                  <h6>Use</h6>
                  Gyan Fresh e-wallet allows You to load a rupee value onto Your
                  Gyan Fresh e-wallet for use only on the Platform. The value
                  associated with the Gyan Fresh e-wallet is not insured or
                  guaranteed by any person or entity.
                  <br />
                  The rupee value that You load onto your Gyan Fresh e-wallet is
                  a prepayment only for purchasing the goods and availing the
                  services on the Platform in India unless otherwise specified.
                  <br />
                  Gyan Fresh e-wallet can be loaded on the Platform using either
                  cash / credit card / debit card / UPI / Internet Banking / any
                  other RBI approved e-wallets managed and governed by other
                  private or government entities. One Gyan Fresh e-wallet cannot
                  be reloaded by using any other registered/unregistered Gyan
                  Fresh e-wallet.
                  <br />
                  You will need to use Your Gyan Fresh e-wallet to make
                  purchases for the goods and availing the services on the
                  Platform. When You use your Gyan Fresh e-wallet, You will
                  receive the details of Your order and payment in the Account
                  History section of Your Gyan Fresh membership account. The
                  Shopping Credits section of Your Gyan Fresh membership account
                  will provide the remaining balance of your Gyan Fresh
                  e-wallet. You should check your order and payment details to
                  ensure that Your account balance is correct.
                  <br />
                  We reserve the right not to accept any Gyan Fresh e-wallet or
                  otherwise limit use of a Gyan Fresh e-wallet if We reasonably
                  believe that the use is unauthorized, fraudulent or otherwise
                  unlawful. Further, misuse of the Gyan Fresh e-wallet or its
                  benefits in any manner may result in disablement of the Gyan
                  Fresh e-wallet and/or withdrawal of benefits at our sole
                  discretion. Please maintain the Gyan Fresh e-wallet safely and
                  securely.
                  <br />
                  Any misuse of the Gyan Fresh e-wallet will be the sole
                  liability of the User and We will have no liability in this
                  regard.
                </li>
                <li>
                  <h6>Billing Error and Correction</h6>
                  We reserve the right to correct the balance of Your Gyan Fresh
                  e-wallet if there is any reason to believe that a clerical,
                  billing or accounting error has occurred. If You have
                  questions regarding your transaction history or any
                  correction, or if You dispute any transaction or correction
                  that has been assessed against Your Gyan Fresh e-wallet,
                  please email Your concern at care@gyandairy.com We shall
                  conduct an investigation and communicate the results and
                  correct any error that after conducting a thorough
                  investigation in a reasonable timeframe. If no error is found,
                  a suitable explanation shall be communicated to You. We shall
                  have no liability for any billing error unless You provide a
                  notice within 30 days of the date of the transaction in
                  question. You are requested to monitor your transactions and
                  account balances accurately.
                </li>
              </ol>
            </li>
          </ol>
        </li>
        <li>
          <h5>Cancellation/Modification Policy</h5>
          <ol>
            <li>
              We shall confirm and initiate the execution of the order initiated
              by You upon receiving confirmation from You for the same. In the
              event You wish to cancel/modify the complete order or order for
              particular items, You can do so anytime up to the cut-off limit of
              the selected time-slot by indicating the same on the Platform or
              calling our customer service. In such a case We will refund any
              payments already made by You for the order by crediting the same
              to Your e-wallet.  You will be duly informed through message
              notifications of your cancelled order and status of refund. Any
              cancellation / modification of an order after the designated
              cut-off time shall not be entertained and the User will be liable
              to pay for their order.
            </li>
            <li>
              In case of technical and product availability issue, Gyan Fresh
              has the right of cancellation / modification of the order of which
              You will be informed through message / notification. We shall be
              at the liberty to cancel the order initiated by You on the
              Platform if:
              <ol class="chngeicon">
                <li>
                  The designated address to avail the services by You is outside
                  the service zone of Gyan Fresh.
                </li>
                <li>
                  Failure to get your response via phone or any other
                  communication channel at the time of confirmation of the order
                  booking.
                </li>
                <li>
                  iii.Information, instructions and authorization provided by
                  You is not complete or sufficient to execute the transaction
                  initiated by You on the Platform
                </li>
                <li>
                  The outlet which is supposed to process Your order is closed.
                </li>
                <li>
                  No delivery persons are available to perform the services.
                </li>
                <li>
                  Any item for which You have initiated the transaction is not
                  in stock.
                </li>
                <li>
                  If the transaction cannot be processed / completed for reasons
                  not in control of Gyan Fresh such as a force majeure event
                  like a natural calamity, a pandemic, restrictions on movement
                  by government authorities or any such event that prevents Gyan
                  Fresh from freely conducting its operations.
                </li>
              </ol>
            </li>
            <li>
              If we suspect any fraudulent transaction by any customer or any
              transaction which defies the Terms of Use of using the Platform,
              We at Our sole discretion could cancel such orders. We will
              maintain a negative list of all fraudulent transactions and
              customers and would deny access to them or cancel any orders
              placed by them.
            </li>
            <li>
              Bonus wallet amount is not refundable. In case customer wants full
              refund of wallet. He has to provide photo of cancelled cheque
              clearly stating the account where money has to be deposited
            </li>
            <li>
              Reach out to our customer care to get past order and order
              information older than 45 days. Same request will be complied
              within 7 working days
            </li>
          </ol>
        </li>
        <li>
          <h5>Refund</h5>
          <ol>
            <li>
              You shall only be able to claim refunds for transactions initiated
              by You only if You have already pre-paid the amount with respect
              to such order. You shall be eligible to get the refund in the
              following circumstances:
              <ol class="chngeicon">
                <li>
                  You package has been tampered or damaged at the time of
                  delivery, as determined by Gyan Fresh on the basis of the
                  parameters established by Gyan Fresh in its sole discretion.
                </li>
                <li>
                  If the wrong item has been delivered to You, which does not
                  match the description of the goods for which You had initiated
                  a transaction on the Platform.
                </li>
                <li>
                  Gyan Fresh cancelled the order for reasons mentioned in Clause
                  6.2.
                </li>
              </ol>
            </li>
            <li>
              All decisions with respect to refunds will be at the sole
              discretion of Gyan Fresh and in accordance with Gyan Fresh’s
              internal refund policy and the same shall be final and binding.
              All refunds initiated by Gyan Fresh shall be refunded to the Gyan
              Fresh e-wallet maintained by the concerned User on the Platform
              within 7-10 business days.
            </li>
          </ol>
        </li>
         <!-- <li>
          <h5>Loyalty Program</h5>
          <ol>
            <li>
              We offer a loyalty program to all the users registered with the
              Gyan Fresh Platform.
            </li>
            <li>
              The Loyalty Program by Gyan Fresh allows the registered users to
              accumulate Loyalty Stars for transactions and other eligible
              actions as may be specified by Us from time to time. Loyalty Stars
              accumulated by such transactions and other eligible actions may be
              redeemed for a wide variety of rewards as may be offered by the
              Gyan Fresh Loyalty Program from time to time.
            </li>
            <li>
              Program Structure
              <ol class="chngeicon">
                <li>
                  Gyan Fresh shall determine, from time to time, at its
                  discretion, which transaction and / or any other action is
                  eligible for inclusion / exclusion in the Loyalty Program and
                  how many Loyalty Stars to be earned and on what basis.
                </li>
                <li>
                  Gyan Fresh shall determine the products / services that the
                  Loyalty Stars can be redeemed for and shall intimate the same
                  to the Users. The catalogue of products / services that the
                  Loyalty Stars can be redeemed for shall be provided on the
                  Application itself for the Users to choose from.
                </li>
                <li>
                  Gyan Fresh will also determine from time to time the number of
                  Loyalty Stars required for redemption of any reward. A
                  Registered User must be a customer in good standing, having
                  earned a minimum number of Loyalty Stars as determined by Us
                  to be eligible for redemption.
                </li>
                <li>
                  In case of a reversal of any eligible transaction, loyalty
                  stars credited to the User as a result of such eligible
                  transaction will be deducted from the accrued loyalty stars
                  balance.
                </li>
                <li>
                  Loyalty Stars can be earned only as per the terms determined
                  by Gyan Fresh. More specifically, during a promotion, special
                  offer, cash-back or black-out period, Gyan Fresh may decide to
                  disallow the earning of loyalty stars on already discounted
                  transactions.
                </li>
                <li>
                  Loyalty Stars earned through eligible actions will be credited
                  to the User within 5 working days.
                </li>
                <li>
                  The above program is subject to change from time to time as
                  per the policy of Gyan Fresh at its sole discretion and Gyan
                  Fresh has the right to amend the above conditions and
                  eligibility norms at any time without notice to the User.
                </li>
                <li>
                  Loyalty stars are not assignable and cannot be pledged /
                  transferred / assigned / redeemed for cash by the User.
                </li>
                <li>
                  Loyalty stars are valid for a period of six months, starting
                  from the day of the month on which the loyalty stars are
                  earned. For example, if a User has earned loyalty stars on
                  10.06.2021, the loyalty stars will be valid till 10.12.2021.
                  Unless used prior to expiration date, the loyalty stars shall
                  expire on such date.
                </li>
                <li>
                  Upon expiration, unused loyalty stars will be removed from the
                  User’s account as of the expiration date and cannot be
                  re-credited. It is the User’s responsibility to be aware of
                  both the number of loyalty stars in their account and their
                  expiration date.
                </li>
              </ol>
            </li> 


            <li>
              Redemption
             
              <ol>
                <li>
                  Redemption of Loyalty Stars is subject to (i) Gyan Fresh
                  approval and terms and conditions as relevant to the reward;
                  (ii) availability of the reward at the time of redemption
                  request and (iii) restrictions imposed by any supplier or
                  issuer of the reward.
                </li>
                <li>
                  Whenever deemed necessary, Gyan Fresh may, without notice,
                  cancel the loyalty reward order or substitute the reward with
                  another, of comparable nature and value, as determined by Gyan
                  Fresh.
                </li>
                <li>
                  Gyan Fresh reserves the right to introduce new products and /
                  or services and replace the existing ones at any point in time
                  without prior intimation to the users.
                </li>
                <li>
                  Physical rewards redeemed shall be delivered at addresses
                  registered with Gyan Fresh and in India only, unless stated
                  otherwise in the reward description. Instant e-vouchers, if
                  offered as a reward, will be delivered to the registered
                  e-mail id as provided by the User, instantly. In case of any
                  unforeseeable situations it will be delivered maximum within
                  24 hours. Instant e-vouchers once redeemed cannot be
                  cancelled.
                </li>
                <li>
                  All e-vouchers redeemed under the program will be redeemable
                  only in India at the respective brand unless explicitly
                  specified otherwise.
                </li>
                <li>
                  Loyalty Stars cannot be exchanged for cash or credit or be
                  used to obtain cash advances or be used against payment for
                  any charges incurred while availing the services in the
                  Platform.
                </li>
                <li>
                  Redeemed rewards are not exchangeable for other rewards or
                  refundable, replaceable or transferable for cash or credit
                  under any circumstances, nor can these be re-converted back to
                  loyalty stars.
                </li>
                <li>
                  Gyan Fresh makes no warranties or representations either
                  expressed or implied, with regard to the type, quality or
                  fitness of the rewards provided through the loyalty program.
                  Rewards will be accompanied by warranty information wherever
                  applicable from the manufacturer and claims must be directed
                  to the manufacturers directly.
                </li>
                <li>
                  Gyan Fresh does not accept responsibility for the quality of
                  goods or services provided by the merchant establishments
                  participating in the loyalty program. All disputes in this
                  regard shall be taken up directly by the User with the
                  participating establishment.
                </li>
                <li>
                  Gyan Fresh shall in no event be responsible or liable for the
                  product and/or services redeemed from the loyalty stars, in
                  any manner whatsoever including but not limited to any defect
                  or deficiency in, or in respect to any claim arising out of
                  non-use or redemption of the Loyalty Stars.
                </li>
                <li>
                  Availability of stock and pricing for any reward in the
                  loyalty program catalogue is subject to change. In case of
                  failure to redeem owing to a technical or network issue, Gyan
                  Fresh will not be liable for said change in the availability
                  of stock or pricing in the interim period.
                </li>
              </ol>
            </li>
            <li>
              Fraud and/or abuse relating to earning and redemption of loyalty
              stars in the program shall result in forfeiture of the loyalty
              stars as well as termination and cancellation of the account
              maintained by the User on the Platform.
            </li>
            <li>
              All communications related to the loyalty program will be sent on
              the email ID provided by the User and maintained in the User
              Account. By giving Us the registered email ID, You are giving Us
              the permission to send communication and promotional offers
              related to the loyalty program on the registered email ID.
            </li>
            <li>
              Any taxes or other liabilities or charges payable to the
              Government or any other authority/body or any other participating
              merchant establishment which may arise or accrue to a User on
              redemption of the loyalty stars as aforesaid or otherwise shall be
              the sole responsibility of the User.
            </li>
            <li>
              Gyan Fresh makes no representation and provides no warranty
              whatsoever, expressed or implied and undertakes and assumes no
              liability whatsoever, in respect of the quality or merchantability
              of any reward or the suitability or fitness thereof for any use or
              purpose. All loyalty rewards shall be accepted by the User at
              his/her own risk.
            </li>
            <li>
              In the event of any breach by a User of these Terms of Use, Gyan
              Fresh reserves the right to:
              <ol>
                <li>
                  Terminate and close the User Account maintained on the
                  Platform
                </li>
                <li>Refuse to award loyalty stars</li>
                <li>Withdraw loyalty stars</li>
                <li>Refuse to redeem loyalty stars</li>
                <li>
                  Forfeit all the accrued loyalty stars available with the User
                </li>
              </ol>
            </li>
            <li>
              Gyan Fresh reserves the right to wholly or partly modify the
              loyalty program. Gyan Fresh also reserves the right to change any
              of the terms and conditions and withdraw loyalty rewards awarded
              in its absolute discretion and without prior notice to members. In
              case the loyalty program comes in conflict with any rule,
              regulation or order of any statutory authority, then Gyan Fresh
              has absolute authority and right to modify or cancel this program
              to give effect to the said requirement.
            </li>
          </ol>
        </li> -->


        <li>
          <h5>Governing law and jurisdiction</h5>
          This Terms of Use shall be construed in accordance with the applicable
          laws of India. The Courts at Lucknow shall have exclusive jurisdiction
          in any proceedings arising out of this Terms of Use. Any dispute or
          difference either in interpretation or otherwise, of any terms of this
          Terms of Use between the parties hereto, same shall be referred to an
          independent arbitrator who will be appointed by Gyan Fresh and his
          decision shall be final and binding on the parties hereto. The
          arbitration shall be in accordance with the Arbitration and
          Conciliation Act, 1996 as amended from time to time. The seat of
          arbitration shall be Lucknow.
        </li>
        <li>
          <h5>Indemnity</h5>
          <ol>
            <li>
              You agree to defend, indemnify and hold harmless C.P. Milk and
              Food Products Private Limited, its employees, directors, officers,
              agents and their successors and assigns
              (<strong>“Indemnitees”</strong>) from and against any and all
              claims, liabilities, damages, losses, costs and expenses,
              including attorney's fees, caused by or arising out of claims
              based upon Your actions or inactions, which may result in any loss
              or liability to Us or any third party including but not limited to
              breach of any warranties, representations or undertakings or in
              relation to the non-fulfilment of any of Your obligations under
              this Terms of Use or arising out of Your violation of any
              applicable laws, regulations including but not limited to
              Intellectual Property Rights, payment of statutory dues and taxes,
              claim of libel, defamation, violation of rights of privacy or
              publicity, loss of service by other subscribers and infringement
              of intellectual property or other rights.
            </li>
            <li>
              You agree to hold the Indemnitees harmless against any claims made
              by any third party due to, or arising out of, or in connection
              with, Your use of the Platform, services provided therein, any
              misrepresentation with respect to the data or information provided
              by You in relation to the Account, Your violation of Terms of Use,
              or Your violation of any right of another, including any
              intellectual property rights.
            </li>
            <li>
              In no event shall the Indemnitees, be liable to You or any third
              party for any special, incidental, indirect, consequential or
              punitive damages whatsoever, arising out of or in connection with
              Your use or access to the Platform or Gyan Fresh Property on the
              Platform.
            </li>
            <li>
              Your indemnification obligation under the Terms of Use will
              survive the termination of Your Account or use of the Platform or
              the services provided therein.
            </li>
          </ol>
        </li>
        <li>
          <h5>Termination</h5>
          <ol>
            <li>
              These Terms of Use are effective unless and until terminated by
              either You or Us. You may terminate these Terms of Use at any
              time, provided that You discontinue any further use of the
              Platform. We may terminate these Terms of Use at any time and may
              do so immediately without notice, and accordingly deny You access
              to Your account on the Platform. Such termination will be without
              any liability on Us. Upon any termination of the Terms of Use by
              either You or Us, You must promptly destroy all materials
              downloaded or otherwise obtained from the Platform, as well as all
              copies of such materials, whether made under the Terms of Use or
              otherwise. Our right to any comments shall survive any termination
              of these Terms of Use.
            </li>
            <li>
              Any such termination of the Terms of Use shall not cancel Your
              obligation to pay for the product already ordered from the
              platform or affect any liability that may have arisen under these
              Terms of Use.
            </li>
            <li>
              We may delist You or block Your future access to the Platform or
              suspend or terminate Your Account if we believe, at our sole
              discretion that You have infringed, breached, violated, abused, or
              unethically manipulated or exploited any term of these Terms of
              Use or anyway otherwise acted unethically.
            </li>
            <li>
              You hereby agree and acknowledge, upon termination that We shall
              have the right to retain all information pertaining to You and the
              transactions initiated by You on the Platform.
            </li>
          </ol>
        </li>
        <li>
          <h5>Representations and Warranties</h5>
          <ol>
            <li>
              Subject to compliance with the Terms of Use, Gyan Fresh grants You
              a non-exclusive, limited privilege to access and use this Platform
              and its services.
            </li>
            <li>
              You agree to:
              <ol>
                <li>
                  Use the Platform only for purposes that are permitted by this
                  Terms of Use;
                </li>
                <li>
                  Use the Platform in accordance with applicable laws,
                  regulations and guidelines;
                </li>
                <li>
                  Not engage in activities that may adversely affect the use of
                  the Platform by Gyan Fresh or other Users.
                </li>
              </ol>
            </li>
            <li>
              You represent and warrant that You have not received any notice
              from any third party or any governmental authority and no
              litigation is pending against You in any court of law, which
              prevents You from accessing the Platform and / or availing its
              Services.
            </li>
            <li>
              You represent and warrant that You are legally authorized to view
              and access the Platform and avail its services.
            </li>
            <li>
              You agree not to access (or attempt to access) the Platform by any
              means other than through the interface that is provided by Us. You
              shall not use any deep-link, robot, spider or other automatic
              device, program, algorithm or methodology, or any similar or
              equivalent manual process, to access, acquire, copy or monitor any
              portion of the Platform, or in any way reproduce or circumvent the
              navigational structure or presentation of the Platform, materials
              or any Gyan Fresh property, to obtain or attempt to obtain any
              materials, documents or information through any means not
              specifically made available through the Platform.
            </li>
            <li>
              You acknowledge and agree that by accessing or using the Platform,
              You may be exposed to content from others that You may consider
              offensive, indecent or otherwise objectionable. We disclaim all
              liabilities arising in relation to such offensive content on the
              Platform.
            </li>
            <li>
              You agree and acknowledge that the use of services offered by Us
              through the Platform is at Your sole risk and that We disclaim all
              representations and warranties of any kind, whether express or
              implied as to condition, suitability, quality, merchantability and
              fitness for any purposes to the fullest extent permitted by law.
            </li>
            <li>
              Without prejudice to the above, We make no representation or
              warranty that our goods and services will meet Your requirements.
            </li>
            <li>
              Further, You undertake not to:
              <ol>
                <li>
                  Defame, abuse, harass, threaten or otherwise violate the legal
                  rights of others;
                </li>
                <li>Do any such thing as may harm the minors in any way;</li>
                <li>
                  Copy, publish, post, display, translate, transmit, reproduce
                  or distribute any Gyan Fresh property through any medium
                  without obtaining the necessary authorization from Us;
                </li>
                <li>
                  Engage in any activity that interferes with or disrupts access
                  to the Platform (or the servers and networks which are
                  connected to the Platform);
                </li>
                <li>
                  Attempt to gain unauthorized access to any portion of the
                  Platform, any other systems or networks connected to the
                  Platform, to any Gyan Fresh server, or through the Platform,
                  by hacking, password mining or any other illegitimate means;
                </li>
                <li>
                  Probe, scan or test the vulnerability of the Platform or any
                  network connected to the Platform, nor breach the security or
                  authentication measures on the Platform or any network
                  connected to the Platform. You may not reverse look-up, trace
                  or seek to trace any information on any other User, of or
                  visitor to, the Platform, to its source, or exploit the
                  Platform or information made available or offered by or
                  through the Platform, in any way whether or not the purpose is
                  to reveal any information, including but not limited to
                  personal identification information, other than Your own
                  information, as provided on the Platform;
                </li>
                <li>
                  Disrupt or interfere with the security of, or otherwise cause
                  harm to, the Platform, systems resources, accounts, passwords,
                  servers or networks connected to or accessible through the
                  Platform or any affiliated or linked sites;
                </li>
                <li>
                  Use any device or software to interfere or attempt to
                  interfere with the proper working of the Platform or any
                  transaction being conducted on the Platform, or with any other
                  person’s use of the Platform;
                </li>
                <li>
                  Use the Platform or any material or Gyan Fresh property for
                  any purpose that is unlawful or prohibited by these Terms of
                  Use, or to solicit the performance of any illegal activity or
                  other activity which infringes the rights of the Company or
                  other third parties;
                </li>
                <li>Impersonate any other user or person;</li>
                <li>Violate the Terms of Use contained herein or elsewhere.</li>
              </ol>
            </li>
          </ol>
        </li>
        <li>
          <h5>Intellectual Property Rights</h5>
          <ol>
            <li>
              The Platform and process, and their selection and arrangement,
              including but not limited to, all text, videos, graphics, user
              interfaces, visual interfaces, sounds and music (if any), artwork,
              algorithm and computer code (and any combination thereof), except
              any third party software available on the Platform, is owned by
              Gyan Fresh (“Gyan Fresh Property”) and the design, structure,
              selection, co-ordination, expression, look and feel and
              arrangement of such Gyan Fresh Property is protected by copyright,
              patent and trademark laws, and various other intellectual property
              rights. You are not permitted to use Gyan Fresh Property without
              the prior written consent of Gyan Fresh.
            </li>
            <li>
              All materials/content on Our Platform (except any third party
              content available on the Platform), including, without limitation,
              names, logos, trademarks, images, text, columns, graphics, videos,
              photographs, illustrations, artwork, software and other elements
              (collectively, "Material") are protected by copyrights, trademarks
              and/or other intellectual property rights owned and controlled by
              Us. You acknowledge and agree that the Material is made available
              for limited, non-commercial, personal use only. Except as
              specifically provided herein or elsewhere in our Platform, no
              Material may be copied, reproduced, republished, sold, downloaded,
              posted, transmitted, or distributed in any way, or otherwise used
              for any purpose other than the purposes stated under this Terms of
              Use, by any person or entity, without Our prior express written
              permission. You may not add, delete, distort, or otherwise modify
              the Material. Any unauthorized attempt to modify any Material, to
              defeat or circumvent any security features, or to utilize our
              Platform or any part of the Material for any purpose other than
              its intended purposes is strictly prohibited. Subject to the above
              restrictions under this Clause, Gyan Fresh hereby grants You a
              non-exclusive, freely revocable (upon notice from Us),
              non-transferable access to view the Material on the Platform.
            </li>
          </ol>
        </li>
        <li>
          <h5>Disclaimer of Warranties & Liabilities</h5>
          You expressly understand and agree that, to the maximum extent
          permitted by applicable law:
          <ol>
            <li>
              The Platform and Gyan Fresh Property and services provided by Us
              to You on an “as is” basis without warranty of any kind, express,
              implied, statutory or otherwise, including the implied warranties
              of title, non-infringement, merchantability of fitness for a
              particular purpose. Without limiting the foregoing, We make no
              warranty that (i) the Platform and services provided therein will
              meet Your requirements or Your use of the Platform will be
              interrupted, secure or error-free; (ii) the quality of the
              Platform will meet Your expectations; or (iii) any errors or
              defects in the Platform will be corrected. No advice or
              information whether oral or written, obtained by, You from Us
              shall create any warranty not expressly stated in the Terms of
              Use.
            </li>
            <li>
              We will not be liable for any loss that You may incur as a
              consequence of unauthorized use of Your Account or Account
              information in connection with the Platform either with or without
              Your knowledge.
            </li>
            <li>
              We shall not be responsible for the delay or inability to use the
              Platform, its services or related functionalities, the provision
              of or failure to provide functionalities, or for any information,
              software, functionalities and related graphics obtained through
              the Platform, or otherwise arising out of the use of the Platform,
              whether based on contract, tort, negligence, strict liability or
              otherwise.
            </li>
            <li>
              We shall not be held responsible for non-availability of the
              Platform during periodic maintenance operations or any unplanned
              suspension of access to the Platform that may occur due to
              technical reasons or for any reason beyond Our control. You
              understand and agree that any material or data downloaded or
              otherwise obtained through the Platform is done entirely at Your
              own discretion and risk, and that You will be solely responsible
              for any damage to Your computer systems or loss of data that
              results from the download of such material or data.
            </li>
          </ol>
        </li>
        <li>
          <h5>Violation of the Terms of Use</h5>
          You agree that any violation by You of these Terms of Use will cause
          irreparable harm to Us or other users as the case maybe, for which
          monetary damages would be inadequate, and You consent to Us or other
          users obtaining any injunctive or equitable relief that is deemed
          necessary in such circumstances. These remedies are in addition to any
          other remedies that We or other users may have at law or in equity. If
          we or other users take any legal action against You as a result of
          Your violation of these Terms of Use, we/they shall be entitled to
          recover from You, and You agree to pay, all reasonable attorney’s fees
          and costs of such action, in addition to any other relief that may be
          granted.
        </li>
        <li>
          <h5>Additional Terms</h5>
          We may require You to follow additional rules, guidelines or other
          conditions from time to time, including in order to participate in
          certain promotions or activities available through the Platform. These
          additional terms shall form a part of this Terms of Use, and You agree
          to comply with them when You participate in those promotions, or
          otherwise engage in activities governed by such additional terms.
        </li>
        <li>
          <h5>Miscellaneous</h5>
          <ol>
            <li>
              We reserve the unconditional right to modify or amend these Terms
              of Use without any requirement to notify You of the same. You can
              determine when these Terms of Use was last modified by referring
              to the “Last Updated” legend. It shall be Your responsibility to
              check these Terms of Use periodically for changes. Your use of the
              Platform and amended Terms of Use shall signify Your consent to
              such changes and agreement to be legally bound by the same.
            </li>
            <li>
              All notices from Us will be served by email to Your registered
              email address or by general notification on the Platform.
            </li>
            <li>
              You cannot assign or otherwise transfer the Terms of Use, or any
              rights granted hereunder to any third party. Our rights under the
              Terms of Use are freely transferable by Us to any third party
              without the requirement of informing You or seeking Your consent.
            </li>
            <li>
              If, for any reason, a court of competent jurisdiction finds any
              provision of the Terms of Use, or any portion thereof, to be
              unenforceable, the provision shall be enforced to the maximum
              extent permissible so as to give effect to the intent of the
              parties as reflected by that provision, and the remainder of the
              Terms of Use shall continue in full force and effect.
            </li>
            <li>
              Any failure by Us to enforce or exercise any provision of the
              Terms of Use, or any related right shall not constitute a waiver
              by Gyan Fresh of that provision or right.
            </li>
            <li>
              These Terms of Use together with Our Privacy Policy and other
              legal notices, communications published by Us on our Platform, and
              any other agreements executed between You and Gyan Fresh shall
              constitute the entire agreement between You and Gyan Fresh
              concerning its Platform and the services provided therein and
              governs Your use of the Platform and the services provided
              therein, suspending any prior agreements between You and Gyan
              Fresh with respect to the Platform and the services provided
              therein.
            </li>
          </ol>
        </li>
        <li>
          <h5>Refer a Friend:</h5>
          <ol>
            <li>
              Invite your friend on Gyan Fresh by sharing your referral code.
              However, the Referrer and the Referee cannot have the same address
            </li>
            <li>
              Before sharing the code, you need to recharge your Gyan Fresh
              wallet with min ₹1000
            </li>
            <li>
              You are eligible to earn ₹25 only when your friend recharge with
              min ₹500 in their Gyan Fresh wallet after the Sign Up, using the
              code shared by you
            </li>
            <li>Your friend earns ₹25 once recharged successfully</li>
            <li>You can refer maximum 5 new users</li>
            <li>
              Gyan Fresh at any time reserves the right to modify or amend Terms
              and Conditions
            </li>
          </ol>
        </li>
          <!-- <li>
            <h5>Milestone/Daily Bonus Rewards</h5>
          <p>Milestone rewards for our best customer</p>
          <p>How it works ?</p>
          <p>Order daily to win more and more. Get incremental rewards for maintaining
            ordering streak.</p>
            <p>Offer Details:</p>
            <ol>
              <li>Customers are rewarded for ordering regularly</li>
              <li>Incremental rewards are given to customers basis their purchase</li>
              <li>Missing a day resets the streak to day 0</li>
              <li>Rewards are redeemed at Gyan star store</li>
               <li>New customer cash back can be redeemed only once on an address. Duplicate address will not qualify for additional cashback/offers.</li>              
              <li>Gyan Fresh reserves the right to change the offers at their discretion</li>
            </ol>
          </li> -->

          <!-- <li>
            <h5>5 days free milk (Weekly)</h5>
          <ol>
            <li>Every 5th day one day free up to maximum 5 days free milk</li>
            <li>Customer should activate milk subscription</li>
            <li>500ml packet of FCM will be delivered on 5th day free of cost</li>
            <li>Offer valid for 60 days from receiving of offer</li>
            <li>Offer is void if the customer unsubscribes</li>
            <li>Coupons applicable once per address</li>
            <li>Gyan Fresh reserves the right to withdraw the offer at any point in time</li>
          </ol>
          </li> -->
        <!-- <li>
          <h5>7 days free milk (Weekly)</h5>
        <ol>
          <li>Every 7th day one day free up to maximum 7 days free milk</li>
          <li>Customer should activate milk subscription</li>
          <li>500ml packet of FCM will be delivered on 7th day free of cost</li>
          <li>Offer valid for 60 days from receiving of offer</li>
          <li>Offer is void if the customer unsubscribes</li>
          <li>Coupons applicable once per address</li>
          <li>Gyan Fresh reserves the right to withdraw the offer at any point in time</li>
        </ol>
        </li> -->
        <li>
          <h5>
            Reversal of payment made via credit card are liable for deduction of
            standard charges.
          </h5>
        </li>
      </ol>
    </div>
  </div>
</div>
