import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  baseUrl = environment.api_url;
  adminRoute = `https://replica.gyandairy.com/api/admin/v1/`;

  constructor(private httpClient: HttpClient) {}

  fetch() {
    return this.httpClient.get('https://jsonplaceholder.typicode.com/todos/1');
  }

  // getAuthRequired(authRequired, contentTypeRequired) {
  //   if (authRequired == true && contentTypeRequired == false) {
  //     return 1;
  //   } else if (authRequired == false && contentTypeRequired == true) {
  //     return 2;
  //   } else if (authRequired == true && contentTypeRequired == true) {
  //     return 3;
  //   }
  // }

  getApi(url): Observable<any> {
    const finalUrl = this.baseUrl + url;
    return this.httpClient.get(finalUrl);
  }

  postApi(url, data): Observable<any> {
    const finalUrl = this.baseUrl + url;
    return this.httpClient.post(finalUrl, data);
  }

  deleteApi(url): Observable<any> {
    const finalUrl = this.baseUrl +  url;
    return this.httpClient.delete(finalUrl);
  }

  putApi(url, data): Observable<any> {
    const finalUrl = this.baseUrl + url;
    return this.httpClient.put(finalUrl, data);
  }

  postApiAdmin(url, data): Observable<any> {
    const finalUrl = this.adminRoute + url;
    return this.httpClient.post(finalUrl, data);
  }
}
